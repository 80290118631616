<template>
  <v-container>
       <iic></iic>
  </v-container>
</template>
<script>
import iic from "@/components/investigacion/iic.vue";
export default {
  components: { iic },
};
</script>